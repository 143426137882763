<template>
	<Offer>
		<template #banner>
			<img slot="banner" v-if="mobile === false" src="../../../../../assets/images/banners/succession.png" alt="banner">
			<img slot="banner" v-else src="../../../../../assets/images/mobile/succession.png" alt="banner">
		</template>

		<template #button>
			<i v-if="mobile === true" class="fas fa-arrow-left"></i>
			<router-link slot="button" to="/oferta">Wróć do spis usług</router-link>
		</template>

		<template #small-title>
			<h4>PRAWO GOSPODARCZE</h4>
		</template>

		<template #title>
			<h2>Sukcesja w firmach</h2>
		</template>

		<template #text>
			<p>Sukcesja przedsiębiorstw wymaga zarówno szczegółowego zaplanowania, jak i rzetelnego przeprowadzenia.
				Jest to proces długotrwały, czasem nawet wieloletni. Aby umożliwić przedsiębiorcom przemyślane
				przekazanie swego dorobku, a także zapewnienie ciągłości i trwałości firmie, tworzymy indywidualne plany
				sukcesji majątkowej przedsiębiorstw. Po dokonaniu analizy przedsiębiorstwa tworzymy struktury i
				mechanizmy, które umożliwiają funkcjonowanie rodzinie i ich biznesom, co najmniej przez kilka
				pokoleń.</p>
		</template>

		<template #range>
			<h3>Zakres:</h3>
		</template>
		<template #list>
			<ul class="offer__list">
				<li class="offer__list-item">Analiza bieżącego stanu majątku, przygotowanie kompleksowego planu
					sukcesji
				</li>
				<li class="offer__list-item">Przygotowywanie pełnej dokumentacji sukcesyjnej</li>
				<li class="offer__list-item">Przeprowadzenie niezbędnych zmian i przekształceń podmiotów gospodarczych
				</li>
				<li class="offer__list-item">Zabezpieczenie majątku pod względem prawa spadkowego
					i korporacyjnego
				</li>
			</ul>
		</template>
	</Offer>
</template>

<script>
import Offer from '../Offer'
import {handleWith} from "@/mixins/globalMixins";

export default {
	mixins: [handleWith],
	components: {
		Offer
	},
	name: 'succession in companies',
}
</script>
